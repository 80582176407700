import React, { useState, useCallback } from 'react';
import Btn from '../../components/Btn/Btn';
import Card from '../../components/Card/Card';
import { Column, Container } from '../../components/Containers/Containers';
import { H1 } from '../../components/Text/Text';
import basic from '../../assets/simulator.png'
import plus from '../../assets/plus-simulator.png'
import Imagen from '../../components/Imagen';
import  Modal from '../../components/Modal/Modal';
import useScreenSize from '../../hooks/useScreenSize';

const Equipos = (props) => {

  const { forwardRef } = props
  const {width}=useScreenSize()
  const [modal, setModal] = useState(false);
  const [simType, setSimType] = useState('');

  const setRefs = useCallback(
    (node)=>{
        forwardRef.current = node;
    }, [forwardRef])

  const showModal = (sim) => {
    setSimType(sim)
    setModal(true)
  }

  const hideModal = () => {
    setModal(false)
  }


  return <Container ref={setRefs}
            position={'relative'} 
            flexFlow={'column nowrap'} 
            justifyContent={'center'}
            padd={'150px 0px 0px 0px'}>

            <H1 style={{ marginBottom: '32px' }} >Equipos</H1>
            <Container width={'80%'} justifyContent={'space-around'} flexFlow={width >= 768 ? 'row no-wrap': 'column wrap'}>
                <Column style={{ marginBottom: '32px' }} width={width >= 768 ?'50%':'100%'} alignItems={'Center'}>
                  <Card padding={width >= 768 ?"32%":"48%"} width={width >= 768 ?'40%':'100%'}radius={"10px"} height={"100%"}>
                    <Imagen src={basic} width={'100%'} height={'100%'} pos={'absolute'}/>
                  </Card>
                  <Btn text={'DriVR Basic'} width={width >= 768 ?'40%':'70%'} mt={'-20px'} callBack={()=>showModal('basico')}/>
                </Column>
                <Column style={{ marginBottom: '32px' }}  width={width >= 768 ?'50%':'100%'} alignItems={'Center'}>
                  <Card padding={width >= 768 ?"32%":"48%"} width={width >= 768 ?'40%':'100%'} radius={"10px"} height={"100%"}>
                    <Imagen src={plus} width={'100%'} height={'100%'} pos={'absolute'}/>
                  </Card>
                  <Btn text={'DriVR Plus'} width={width >= 768 ?'40%':'70%'} mt={'-20px'} callBack={()=>showModal('plus')} />
                </Column>
            </Container>
            <Modal visible={modal} sim={simType} callBack={hideModal}/>

        </Container>
};

export default Equipos;
