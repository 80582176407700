import styled from 'styled-components';

const ContainerGral = styled.div`
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left:0;
    z-index: 1;
`

export default ContainerGral;
