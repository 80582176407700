import styled from 'styled-components'
import Glass from '../Glass/Glass'

const Card =({width, height, padding, radius, children, mt, margin, glasspad, overflow})=>{

    return(
        <CardCont
            width={width}
            height={height}
            padding={padding}
            radius={radius}
            mt={mt}
            margin={margin}
            overflow={overflow}>
           
            {children}
            <Glass glasspad={glasspad}/>
        </CardCont>
    )
}

export default Card

const CardCont = styled.div`
    width: ${props => props.width};
    height: ${props => props.height};
    padding: ${props => props.padding};
    border-radius: ${props => props.radius};
    margin:${props=>props.margin};
    overflow:${props=>props.overflow};
    position: relative;
    box-sizing: border-box;
`