import React from 'react';
import styled from 'styled-components';
import Nav from '../Nav/Nav'
import useScreenSize from '../../hooks/useScreenSize';

const MobileNav =({toogle, setToogle})=>{

    const {width}=useScreenSize()

    return(
        <Wrapper top={toogle ? '0vh' : '-100vh'} display={width >= 1025 ? 'none':'flex'} padding={width <= 845? '22% 0%':'12% 0%'} >
            <Nav width={'100%'} display={'flex'} 
            flow={'column'} align={'start'}
            toogle={toogle} setToogle={setToogle} 
              />
        </Wrapper>
    );
}

export default MobileNav

const Wrapper = styled.div`
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 14;
    background-color: var(--darkGray);
    display: ${props=>props.display};
    justify-content: space-between;
    padding: ${props=>props.padding};
    text-align: center;
    overflow-y:hidden;
    transition: ease 1s;
    top: ${props=>props.top};
`
