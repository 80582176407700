import styled from 'styled-components';
import useScreenSize from '../../hooks/useScreenSize';
import { P } from '../Text/Text';

const List = ({arrayList , bullets}) => {
  
  const {width }=useScreenSize()
  
  return (
      <ContainerList width={width >= 768?'50%': '100%'}>
      { arrayList.map((item, i)=>
            <Wrapper key={i} >
                {bullets && <Bullet/>}
                <P>{item}</P>
            </Wrapper>
      ) }
      </ContainerList>
  );
};

export default List;

const Bullet = styled.div`
    width: 12px;
    height: 12px;
    background-color: #F6871F;
    border-radius: 16px;
`
const Wrapper = styled.div`
    display: flex;
    flex-flow: row no-wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px
`

const ContainerList = styled.div`
    display: flex;
    flex-flow: column wrap;
    width: ${props => props.width};
    justify-content: center;
    align-items: center;
    margin-top: 24px
`