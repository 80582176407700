import React from 'react'
import styled from 'styled-components'

const Burger =({display, width, toogle, setToogle})=>{

    return(
        <BurgerWrapper display={display} width={width} onClick={() => setToogle(!toogle)}>
            <BurgerLine transform={(toogle) ? 'rotate(45deg) translateY(15px)':'rotate(0deg) translateY(0px)'} />
            <BurgerLine opacity={(toogle) ? '0':'1' } />
            <BurgerLine transform={(toogle) ?'rotate(-45deg) translateY(-15px)':'rotate(0deg) translateY(0px)'} />
        </BurgerWrapper>
    )
}

export default Burger

const BurgerWrapper = styled.div`
    width: ${props=>props.width};
    display: ${props=>props.display};
    flex-flow: column nowrap;
    cursor: pointer;
    z-index: 14;
`

const BurgerLine = styled.div`
    width: 100%;
    height: 0.25rem;
    background-color: var(--orange);
    margin: 3px 0px;
    border-radius: 10px;
    transition: ease 1s;
    &:nth-child(1){
        transform:${props=>props.transform};
    }
    &:nth-child(2){
        opacity:${props=>props.opacity};
    }
    &:nth-child(3){
        transform:${props=>props.transform};
    }
`