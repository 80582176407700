import React, {useCallback} from 'react';
import styled from 'styled-components';
import Title from '../../components/Text/Title';
import { Container } from '../../components/Containers/Containers';
import useScreenSize from '../../hooks/useScreenSize';
import Form from './Form';

const Contacto = (props) => {

  const { forwardRef } = props  
  const {width} = useScreenSize()

  const setRefs = useCallback(
    (node)=>{
        forwardRef.current = node;
    }, [forwardRef])

    return(
        <Container ref={setRefs} flexFlow={'column nowrap'} justifyContent={'center'} padd={'150px 0px 0px 0px'}>
            <Title text={"Contacto"}/>
            <Contact>
                <FormContainer wd={width >= 1025 ? '50%' : '90%'}>
                    <Form/>
                </FormContainer>
            </Contact>
        </Container>
      ) 
};

export default Contacto;

const Contact = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60%
`

const FormContainer = styled.div`
    width: ${props=>props.wd};
    display: flex;
    justify-content: center;
    height: 100%;
    @media (max-width: 1280px) {
        width: 80%;
    }
` 