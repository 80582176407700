import React from 'react'
import styled from 'styled-components';
import NavItem from './NavItem';


const SecId = [0, 1, 2, 3, 4];
const SecName = ['¿Qué es DriVR?', 'Equipos', 'Clientes', 'Galería', 'Contacto' ]


const Nav=(props)=> {

   const {width, display, flow, toogle, setToogle} = props


    return(
        <NavCont width={width} display={display} flow={flow}>
            {SecId.map(i => (
            <NavItem i={i} key={i} sectionTxt={SecName[i]} toogle={toogle} setToogle={setToogle}/>
          ))}
        </NavCont>
    );
}

export default Nav;

const NavCont = styled.div`
    width: ${props=>props.width};
    display: ${props=>props.display};
    justify-content: space-between;
    flex-flow: ${props=>props.flow};
`