import React, {useCallback} from 'react';
import Imagen from '../../components/Imagen';
import simulator from '../../assets/simulator.png'
import List from '../../components/List';
import { P } from '../../components/Text/Text';
import { Column, Container } from '../../components/Containers/Containers';
import useScreenSize from '../../hooks/useScreenSize';

const Simulator = (props) => {

    const { forwardRef } = props
    const {width}=useScreenSize()
    
    const setRefs = useCallback(
        (node)=>{
            forwardRef.current = node;
        }, [forwardRef])

  return <Container ref={setRefs} width={'95%'} justifyContent={'center'} padd={'100px 32px 0px 32px '} flexFlow={width >= 768 ? 'row no-wrap': 'column'} height={'100vh'} margin={'0 auto'}>
            <Column width={width >= 768 ?'50%':'100%'} justifyContent={'center'} alignItems={'center'} >
                <P style={{marginBottom: '24px'}}>Identifica un perfil de riesgo de los conductores basado en su comportamiento frente a situaciones cotidianas.</P>
                <List bullets arrayList={['Riesgo lateral', 'Riesgo delantero', 'Espacios reducidos', 'Intersecciones', 'Población vulnerable', 'Distractores']}/>
            </Column>
            <Column width={width >= 768 ?'50%':'100%'}  alignItems={'center'} >
                <Imagen src={simulator} width={'100%'} height={'100%'} pos={'relative'}/>
            </Column>
        </Container>;
};

export default Simulator;