import React from 'react';
import Btn from '../Btn/Btn';
import Card from '../Card/Card';
import { Column, Container } from '../Containers/Containers';
import Imagen from '../Imagen';
import simulator from '../../assets/simulator.png'
import plus from '../../assets/plus-simulator.png'
import { H1, P } from '../Text/Text';
import List from '../List';
import data from '../../data.json'
import useScreenSize from '../../hooks/useScreenSize';

const Detail = ({callBack, sim}) => {
    
  const {width, height}=useScreenSize()
  
  return    <Container width={'80%'} height={width >= 768?'80vh': height} justifyContent={'space-around'} flexFlow={width >= 768 ? 'row no-wrap': 'column wrap'}>
                <Column style={{ marginBottom: '32px' }}  width={width >= 768 ?'50%':'100%'} alignItems={'Center'}>
                    <Card padding={"32%"} width={"40%"} radius={"10px"} height={"100%"}>
                        <Imagen src={sim==='basico' ? simulator : plus} width={'100%'} height={'100%'} pos={'absolute'}/>
                    </Card>
                    <Btn text={sim==='basico' ? 'Compara con Plus' : 'Compara con Basic'} width={"40%"} mt={'-20px'} callBack={callBack} />
                </Column>
                <Column style={{ marginBottom: '32px' }}  width={width >= 768 ?'50%':'100%'} alignItems={'flex-start'} >
                    {sim === 'basico' ? <>
                        <H1 style={{color: 'white', marginBottom: '12px', textAlign: 'left', marginLeft: '24px'}}>DriVR Basic</H1>
                        {/* <P>Identifica un perfil de riesgo de los conductores basado en su comportamiento frente a situaciones cotidianas.</P> */}
                        <List arrayList={data.basico.bullets} bullets/>
                    </>:
                    <>
                        <H1 style={{color: 'white', marginBottom: '12px', textAlign: 'left', marginLeft: '24px'}}>DriVR Plus</H1>
                        <P>Las mismas características de DriVR Basic y adicionalmente:</P>
                        <List arrayList={data.plus.bullets} bullets/>
                    </>}
                </Column>
            </Container>
};

export default Detail;
