import styled from 'styled-components';

const Btn =({text, width, mt, callBack, position})=>{
    return(
        <ButtonCont wd={width} mt={mt} onClick={callBack} pos={position}>
            <Button>{text}</Button>
        </ButtonCont>
    )
}

export default Btn

const ButtonCont = styled.div`
    width: ${props => props.wd};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: ${props =>props.mt};
    z-index: 20;
    cursor: pointer;
    background-color: #F6871F;
    border-radius: 8px;
    position: ${props =>props.pos};
    bottom: -20px;
    z-index: 13;
    padding: 8px;

    &:hover {
        background-color: #F8A04E;
    }
`
const Button = styled.p`
    width: 100%;
    color: #2F303A;
    font-size: calc(1.125rem + ((.8vw - 19.2px) * 0.375));
    padding: 1%;
    text-align: center;
    font-weight: bold;
`