import React from 'react'
import styled from 'styled-components'
import { H1 } from './Text'

const Title =({text})=>{

    return(
        <TitleWrapper><H1>{text}</H1></TitleWrapper>
    )

}

export default Title

const TitleWrapper = styled.div`
    width: 100%;
    margin-bottom:50px;
    @media (max-width: 768px) {
        margin-bottom:32px;
    }
`