import React, { useEffect, useContext, useRef } from 'react';
import { NavContext } from '../../context/NaviContext';
import ContainerGral from '../../components/ContainerGral';
import Header from '../Header/Header';
import Simulator from '../Simulator/Simulator';
import Hero from '../Hero/Hero';
import Podcast from '../Podcast/Podcast';
import Equipos from '../Equipos/Equipos';
import Metricas from '../Metricas/Metricas';
import Clientes from '../Clientes/Clientes'
import Galeria from '../Galeria/Galeria';
import Contacto from '../Contacto';
import Footer from '../Footer/Footer';

const Home = () => {

  const [section] = useContext(NavContext)



  const refHero = useRef(null)
  const refDrivr = useRef(null)
  const refSimulator = useRef(null)
  const refEquipos = useRef(null)
  const refMetricas = useRef(null)
  const refClientes = useRef(null)
  const refGaleria = useRef(null)
  const refContacto = useRef(null)
  const refFooter = useRef(null)

  const scrollToRef = (ref) => {

    window.scrollTo(0, ref.current.offsetTop);

  }

  useEffect(() => {


    switch (section) {

      case 0:
        scrollToRef(refHero)
        break;
      case 1:
        scrollToRef(refEquipos)

        break;
      case 2:
        scrollToRef(refClientes)
        break;
      case 3:
        scrollToRef(refGaleria)
        break;
      case 4:
        scrollToRef(refContacto)
        break;
      default:
        window.scrollTo(0, 0);
        break;
    }

  }, [section])


  return (

    <ContainerGral>
      <Header />
      <Hero forwardRef={refHero} />
      <Podcast forwardRef={refDrivr} />
      <Simulator forwardRef={refSimulator} />
      <Equipos forwardRef={refEquipos} />
      <Metricas forwardRef={refMetricas} />
      <Clientes forwardRef={refClientes} />
      <Galeria forwardRef={refGaleria} />
      <Contacto forwardRef={refContacto} />
      <Footer forwardRef={refFooter} />
    </ContainerGral>
  )
}

export default Home