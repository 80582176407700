import React,{useCallback} from 'react'
import styled from 'styled-components'
import Card from '../../components/Card/Card'
import Player from '../../components/Video/Player'
import useScreenSize from '../../hooks/useScreenSize'
import { Container } from '../../components/Containers/Containers'

const Podcast =(props)=>{

    const {width}=useScreenSize()
    const { forwardRef } = props

    const setRefs = useCallback(
        (node)=>{
            forwardRef.current = node;
        }, [forwardRef])

    return(
        <Container 
            ref={setRefs} 
            flexFlow={width>=768 ? 'row nowrap': 'column nowrap'}
            justifyContent={'space-evenly'}
            padd={'200px 0px 0px 0px'}
            >
                <PlayerWrap width={width>=768 ? "50%" : "100%"} height={width>=768 ? "60vh" : "40vh"}>
                    <Player 
                        url={'https://youtu.be/pgk3Fgu5Y2s'}
                        playing={false}
                        loop={false}
                        opacity={1}
                        widthCont={"100%"}
                        heightCont={"100%"}
                    />
                </PlayerWrap>
                <Card padding={"6.2%"} width={width>=768 ? "35%" :"90%"} radius={"10px"} height={"100%"} margin={width <= 768 ? '50px 0px 0px 0px':'0px 0px 0px 0px'}>

                        <p>
                            Buscamos la <span>erradicación de las muertes por accidentes viales </span>  
                            y con consecuencias graves, creando <span>experiencias tecnológicas e 
                            inmersivas</span>  de evaluación, gestión y formación de comportamientos 
                            preventivos de personas que conducen o aspiran a conducir un vehículo.
                        </p>
                        {/* <Btn text={"Entornos virtuales en la seguridad vial"} mt={"80px"}/> */}
                </Card>
        </Container>
    )
}

export default Podcast

const PlayerWrap = styled.div`
    width: ${props=>props.width};
    box-shadow: 3px -2px 23px -7px rgba(0,0,0,0.74);
    -webkit-box-shadow: 3px -2px 23px -7px rgba(0,0,0,0.74);
    -moz-box-shadow: 3px -2px 23px -7px rgba(0,0,0,0.74);
    height: ${props=>props.height};
`
