import React, {useCallback} from 'react'
import styled from 'styled-components'
import Imagen from '../../components/Imagen'
import driving from '../../assets/images/simulator.jpg'
import Numeralia from '../../components/Numeralia/Numeralia'
import { Container } from '../../components/Containers/Containers'
import useScreenSize from '../../hooks/useScreenSize'

const Metricas=(props)=>{

    const { forwardRef } = props
    const {width}=useScreenSize()

    const setRefs = useCallback(
        (node)=>{
            forwardRef.current = node;
        }, [forwardRef])

    return(
        <Container ref={setRefs} flexFlow={'column nowrap'} padd={'100px 0px 0px 0px'}>
                <MetricaImage>
                    <Imagen src={driving} width={'100%'} opacity={'.3'}/>
                </MetricaImage>
                <NumerCont width={width>=768?'60%':'90%'}>
                    <Numeralia number={'52'} text={'Simuladores'}/>
                    <Numeralia number={'500'} text={'Personas alcanzadas'}/>
                </NumerCont>
        </Container>
    )
}

export default Metricas

const MetricaImage = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    margin-bottom: 150px;
    @media (max-width: 1025px) {
       
    }
`

const NumerCont = styled.div`
    width: ${props=>props.width};
    margin-top: 150px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
`