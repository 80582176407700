import React from 'react'
import styled from 'styled-components'
import { HighLightP, Number } from '../Text/Text'
import useScreenSize from '../../hooks/useScreenSize';

const Numeralia=({number, text})=>{

    const {width}=useScreenSize()

    return(
        <NumeraliaCont width={width >=1025 ? '30%':'50%'}>
            <Number>+ {number}</Number>
            <NumberTxt>{text}</NumberTxt>
        </NumeraliaCont>
    )
}

export default Numeralia

const NumeraliaCont = styled.div`
    text-align: center;
    width: ${props=>props.width};
    justify-content: space-between;
`

const NumberTxt = styled(HighLightP)`
    margin-top: 30px;
    font-size: calc(1.4rem + ((1vw - 19.2px) * 0.375));
    white-space: pre-line;
`


