import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import Compare from '../../modules/Equipos/Compare';
import Detail from '../Detail/Detail';
import x from '../../assets/images/x.svg'

const Modal = ({visible, sim, callBack}) => {

    const [comp, setComp] = useState(false);

    const showCompare = () =>{
        setComp(true)
    }

    useEffect(() => {
        if(!visible){
          setComp(false)
        }
      }, [visible]);
      

  if(!visible){
     return null
  }

  return <ModalContainer bgColor={'#2F303A'}>
            {!comp ? <Detail sim={sim} callBack={showCompare}/> :
                     <Compare/>
            }
            <ContainerIcon onClick={callBack}>
                <Icon src={x}/>
            </ContainerIcon>
        </ModalContainer>;
};

export default Modal;

export const ModalGallery = ({visible, callBack, img, swipe} ) =>{



    if(!visible){
        return null
     }
    return <ModalContainer bgColor={'transparent'}>
            <Expand src={img} onClick={swipe}/>
            <ContainerIcon onClick={callBack}>
                <Icon src={x}/>
            </ContainerIcon>
            <Overlay onClick={callBack}/>
        </ModalContainer>;
}

const Expand = styled.img`
    position: relative;
    width: 85%;
    height: 85%;
    z-index: 20;
    object-fit: contain
`
const Overlay = styled.div`
    width: 100%;
    height: 100%;
    background-color: #2F303A;
    opacity: 0.9;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    
`
const ModalContainer = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    z-index: 90;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props=>props.bgColor};
`
const ContainerIcon = styled.div`
    position: absolute;
    width: 4%;
    height: 6%;
    top: 3%;
    right: 1%;
    z-index: 40;
    cursor: pointer;
    @media (max-width: 768px) {
        width: 32px;
        height: 32px;
    }
`
const Icon = styled.img`
    width: 100%;
    height: 100%;
`