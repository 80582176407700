import React from "react";
import styled from "styled-components";

function Glass(glasspad) {
  return (
    <ContainerSvg padding={glasspad}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 574 571"
      preserveAspectRatio="none"
    >
      <g filter="url(#filter0_b_519_6)">
        <rect
          width="572"
          height="569"
          x="1"
          y="1"
          fill="url(#paint0_linear_519_6)"
          fillOpacity="0.5"
          rx="16"
        ></rect>
        <rect
          width="573"
          height="570"
          x="0.5"
          y="0.5"
          stroke="url(#paint1_radial_519_6)"
          rx="16.5"
        ></rect>
        <rect
          width="573"
          height="570"
          x="0.5"
          y="0.5"
          stroke="url(#paint2_radial_519_6)"
          rx="16.5"
        ></rect>
        <rect
          width="573"
          height="570"
          x="0.5"
          y="0.5"
          stroke="url(#paint3_radial_519_6)"
          rx="16.5"
        ></rect>
      </g>
      <defs>
        <filter
          id="filter0_b_519_6"
          width="658"
          height="655"
          x="-42"
          y="-42"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feGaussianBlur
            in="BackgroundImage"
            stdDeviation="21"
          ></feGaussianBlur>
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_519_6"
          ></feComposite>
          <feBlend
            in="SourceGraphic"
            in2="effect1_backgroundBlur_519_6"
            result="shape"
          ></feBlend>
        </filter>
        <linearGradient
          id="paint0_linear_519_6"
          x1="1"
          x2="347.793"
          y1="1"
          y2="684.299"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#151515" stopOpacity="0.4"></stop>
          <stop offset="1" stopColor="#151515" stopOpacity="0"></stop>
        </linearGradient>
        <radialGradient
          id="paint1_radial_519_6"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(-364.9362 -395.0485 306.59354 -283.22367 287 285.5)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </radialGradient>
        <radialGradient
          id="paint2_radial_519_6"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(343.19956 293.4414 -210.61292 246.32605 287 285.5)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0938DF"></stop>
          <stop offset="1" stopColor="#0938DF" stopOpacity="0"></stop>
        </radialGradient>
        <radialGradient
          id="paint3_radial_519_6"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(136.107 85.98 200.572) scale(434.97 324.829)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#151515"></stop>
          <stop offset="1" stopColor="#151515" stopOpacity="0"></stop>
        </radialGradient>
      </defs>
    </svg>
    </ContainerSvg>
  );
}

export default Glass;

const ContainerSvg = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    padding: ${props=>props.padding}
`