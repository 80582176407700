import styled from 'styled-components';

export const P = styled.p`
    width: 80%;
    margin-left: 24px;
    text-align: ${props=>props.textAlign};
`
export const pTable = styled.p`
    font-size: calc(1rem + ((2vw - 19.2px) * 0.375));
    line-height: 1rem;
`

export const Number = styled.h1`
    font-size: calc(4rem + ((1vw - 19.2px) * 0.375));
`

export const H1 = styled.h1`
    width: 100%;
    text-align: center
`

export const H3 = styled.h3`
    width: 50%;
    text-align: center
`

export const HighLightP = styled.p`
    color: var(--orange);
`