import styled from 'styled-components';

const Imagen = styled.img`
    width: ${props => props.width};
    height: ${props => props.height};
    position: ${props => props.pos};
    opacity:${props => props.opacity};
    top: 0;
    left: 0;
    z-index: 12;
    margin:${props => props.margin};
`

export default Imagen;