import React, { useState, useCallback } from 'react';
import styled from 'styled-components'
import imag1 from '../../assets/galeria/galeria-drivr1.JPG'
import imag2 from '../../assets/galeria/galeria-drivr2.JPG'
import imag3 from '../../assets/galeria/galeria-drivr3.JPG'
import imag4 from '../../assets/galeria/galeria-drivr4.JPG'
import imag5 from '../../assets/galeria/galeria-drivr5.JPG'
import { Container } from '../../components/Containers/Containers';
import Title from '../../components/Text/Title';
import expand from '../../assets/images/expan.svg';
import { ModalGallery } from '../../components/Modal/Modal';

const Galeria = (props) => {

  const { forwardRef } = props  
  const [openImage, setOpenImage] = useState(false);
  const [currentImg, setCurrentImg] = useState(undefined);
  const toggleImage =(img) =>{
        setCurrentImg(img)
        setOpenImage(true)
  }
 
  const swipe = ()=>{
    console.log('entra swipe');

  }

  const setRefs = useCallback(
    (node)=>{
        forwardRef.current = node;
    }, [forwardRef])

  return(

  <>
         <Container ref={setRefs} style={{flexFlow: 'column'}} width={'100%'} height={'100vh'} padd={'150px 0px 0px 0px'}>
            <Title text={"Galería"}/>
            <GridContainer>
                <GridImage area={'side'} image={imag1} onClick={()=>toggleImage(imag1)}>
                    <Overlay>
                       <Expand src={expand}/>
                    </Overlay>
                </GridImage>
                <GridImage area={'small'} image={imag2} onClick={()=>toggleImage(imag2)}>
                    <Overlay>
                       <Expand src={expand}/>
                    </Overlay>
                </GridImage>
                <GridImage area={'small2'} image={imag3} onClick={()=>toggleImage(imag3)}>
                    <Overlay>
                       <Expand src={expand}/>
                    </Overlay>
                </GridImage>
                <GridImage area={'small3'} image={imag4} onClick={()=>toggleImage(imag4)}>
                    <Overlay>
                       <Expand src={expand}/>
                    </Overlay>
                </GridImage>
                <GridImage area={'small4'} image={imag5} onClick={()=>toggleImage(imag5)}>
                    <Overlay>
                       <Expand src={expand}/>
                    </Overlay>
                </GridImage>
            </GridContainer>
         </Container>
         <ModalGallery visible={openImage} img={currentImg} callBack={()=>setOpenImage(false)} swipe={swipe}/>
         </>
  ) 
};

export default Galeria;


const GridImage = styled.div`
    grid-area: ${props=>props.area};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5rem;
    background-image: url(${props =>props.image});
    background-size: cover;
    background-position: center center ;
    position: relative;
`

const GridContainer = styled.div`
    display: grid;
    grid-template-areas:'side side side small small2''side side side small3 small4';
    grid-gap: 2% 2%;
    grid-row-gap: 6%;
    padding: 0% 4% 0% 4%;
    height: 100%;
    width: 100%;
    @media (max-width: 768px) {
        grid-template-areas:'side side side side ''small small small2 small2''small3 small3 small4 small4';
        grid-gap: 2% 2%;
        grid-row-gap: 2%;
    }
`
const Expand = styled.img`
    position: relative;
    width: 20%;
    height: 20%;
    z-index: 20;
    
`

const Overlay = styled.div`
    width: 100%;
    height: 100%;
    background-color: #2F303A;
    opacity: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        opacity: .8;
        cursor: pointer;
    }
`


