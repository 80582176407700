import React,{ useEffect, useState} from 'react';
import './App.css';
import Home from './modules/Home';

function App() {
  const [config, setConfig] = useState({ paddingTop: '56.6%' });

  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();
    return _ => {
      window.removeEventListener('resize', onResize);
    }
  }, [])

  function onResize() {
    let padding = (window.innerHeight / window.innerWidth) * 100;
    setConfig(prevState => ({
      ...prevState,
      paddingTop: padding + '%'
    }));
  }

  



  return (
        
      <div className="App"  style={config}>
        <Home /> 
        {/* <div className='backdrop'></div>  */}
      </div>
  );
}

export default App;
