import React, {useCallback} from 'react';
import Logo from '../../assets/images/DriVR.svg';
import Linkedin from '../../assets/images/linkedin.svg';
import Facebook from '../../assets/images/facebook.svg';
import Virtuelle from '../../assets/images/logov.png';
import { Column, Container } from '../../components/Containers/Containers';
import Imagen from '../../components/Imagen';
import useScreenSize from '../../hooks/useScreenSize';

const Footer = (props) => {
  const { forwardRef } = props
  const {width}=useScreenSize()

  const setRefs = useCallback(
    (node)=>{
        forwardRef.current = node;
    }, [forwardRef])

  return <Container ref={setRefs}  width={'100%'} padd={'100px 0px 30px 0px'} justifyContent={'center'} flexFlow={width >= 768 ? 'row no-wrap': 'column wrap'}>
            <Column style={{ marginBottom: '32px', }} width={width >= 768 ?'33%':'100%'} justifyContent={'center'} alignItems={'Center'}>
              <Container style={{ marginBottom: '32px', }} flexFlow={'row nowrap'} width={'100%'} justifyContent={'center'} alignItems={'Center'}>
                <Column width={'30%'} justifyContent={'center'} style={{alignContent: 'center'}}>
                  <a href='https://www.linkedin.com/company/drivr-simulador-de-manejo/' target='_blank' rel={'noreferrer'}><Imagen src={Linkedin} width={'30%'}/></a>
                </Column>
                <Column width={'30%'} justifyContent={'center'} style={{alignContent: 'center'}} >
                  <a href='https://www.facebook.com/Drivr-104513804479378' target='_blank' rel={'noreferrer'} ><Imagen src={Facebook} width={'30%'}/></a>
                </Column>
              </Container>
              <a href='/Terminos_y_condiciones.pdf' target='_blank' rel={'noreferrer'} style={{ textAlign: 'center', marginBottom: '32px' }}>Términos y Condiciones</a>
              <a  href='/Aviso_de_privacidad_270921.pdf' target='_blank' rel={'noreferrer'} style={{ textAlign: 'center', marginBottom: '32px' }}>Aviso de Privacidad</a>
            </Column>
            <Column style={{ marginBottom: '32px',  }} width={width >= 768 ?'33%':'100%'} alignItems={'Center'}>
              <p style={{ textAlign: 'center', marginBottom: '32px' }}>Huasteca No.197 Col. Industrial. CP. 07800, Ciudad de México, México</p>
              <p style={{ textAlign: 'center', marginBottom: '32px' }}>contacto@drivr.mx</p>
            </Column>
            <Column style={{ marginBottom: '32px',}} width={width >= 768 ?'33%':'100%'} alignItems={'Center'}>
              <Imagen src={Logo} width={'35%'}/>
              <p style={{ textAlign: 'center', marginBottom: '32px', marginTop: '12px' }}>desarrollado por:</p>
              <a style={{textAlign: 'center', width: '100%', justifyContent: 'center'}} target='_blank' rel={'noreferrer'} href='https://www.virtuelle.mx/'>
              <Imagen src={Virtuelle} width={'35%'}/>
              </a>
            </Column>
         </Container>;
};

export default Footer;
