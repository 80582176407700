import React,{useState} from 'react'
import styled from 'styled-components';
import Imagen from '../../components/Imagen';
import Logo from '../../assets/images/DriVR.svg';
import Nav from '../../components/Nav/Nav';
import Burger from '../../components/Nav/Burger';
import MobileNav from '../../components/Nav/MobileNav';
import useScreenSize from '../../hooks/useScreenSize';
import StycktWhats from '../../components/StickyWhats/StycktWhats';


const Header =()=>{

    const {width}=useScreenSize()
    const [toogle, setToogle] = useState(false)

    return(
        <>
            <MobileNav toogle={toogle} setToogle={setToogle}/>
            <HeaderContainer>
                <Imagen src={Logo} width={width >=768 ? '10%':'20%'}/>
                <Nav width={'70%'} display={width >= 1025 ? 'flex':'none'} toogle={toogle} setToogle={setToogle}/>
                <Burger  toogle={toogle} setToogle={setToogle}
                        display={width >= 1025 ? 'none':'flex'}
                        width={width >= 810 ? '4%' : '7%'}/>
            </HeaderContainer>
            <StycktWhats />
        </>
    )
}

export default Header;

const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 15;
    top: 0%;
    padding: 2%;
    box-sizing: border-box;
    background: rgb(47,48,58);
    background: linear-gradient(180deg, rgba(47,48,58,0.927608543417367) 21%, rgba(47,48,58,0.6923144257703081) 65%, rgba(47,48,58,0) 100%);        
`