import React from 'react'
import styled from 'styled-components';
import ReactPlayer from "react-player";

const Player =({url, loop, playing, opacity, muted, position, widthCont, heightCont})=>{

    return(
        <PlayerCont opacity={opacity} position={position} widthCont={widthCont} heightCont={heightCont}>
            <ReactPlayer
                playing= {playing}
                 url= { url }
                 width= {'100%'}
                 height= {'100%'}
                 controls = {false}
                 loop = {loop}
                 muted= {muted}
                 config={{
                    youtube: {
                      playerVars: { 
                          modestbranding: 0,
                          enablejsapi: 1
                        }
                    }
                  }}
            />
        </PlayerCont>
    )
}

export default Player


const PlayerCont = styled.div`
    opacity: ${props => props.opacity};
    mix-blend-mode: overlay;
    overflow: hidden;
    position: ${props =>props.position};
    width: ${props=>props.widthCont};
    height: ${props=>props.heightCont};
`