import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    flex-flow: ${props => props.flexFlow};
    justify-content: ${props => props.justifyContent};
    align-items: center;
    width: ${props => props.width};
    height: ${props => props.height};
    padding: ${props => props.padd};
    margin: ${props => props.margin};
    position: ${props => props.position};
`

export const Column = styled.div`
    display: flex;
    position: ralative;
    flex-flow: column wrap;
    justify-content: ${props => props.justifyContent};
    align-items: ${props => props.alignItems};
    width: ${props => props.width};
    height: ${props => props.height};
    margin-top:${props=>props.mt}
`