import React,{useCallback} from 'react'
import styled from 'styled-components'
import Title from '../../components/Text/Title';
import { Container } from '../../components/Containers/Containers';
import Imagen from '../../components/Imagen';
import FEMSA from '../../assets/images/femsa.png'
import FEDEX from '../../assets/images/fedex.png'
import RICA from '../../assets/images/rica.png'
import BIMBO from '../../assets/images/bimbo.png'
import useScreenSize from '../../hooks/useScreenSize';

const Clientes =(props)=>{

    const { forwardRef } = props
    const {width}=useScreenSize()

    const setRefs = useCallback(
        (node)=>{
            forwardRef.current = node;
        }, [forwardRef])

    return(
        <Container ref={setRefs} flexFlow={'column'} padd={'150px 0px 0px 0px'} height={'50vh'}>
            <Title text={"Nuestros clientes"}/>
            <LogoCont>
                <Imagen width={width>=768?"15%":"25%"} margin={width>=1025? '0px 0px':'10px 50px'} src={FEMSA}/>
                <Imagen width={width>=768?"15%":"25%"} margin={width>=1025? '0px 0px':'10px 50px'} src={RICA}/>
                <Imagen width={width>=768?"15%":"25%"} margin={width>=1025? '0px 0px':'10px 50px'} src={BIMBO}/>
                <Imagen width={width>=768?"15%":"25%"} margin={width>=1025? '0px 0px':'10px 50px'} src={FEDEX}/>   
            </LogoCont>
        </Container>
    )
}

export default Clientes

const LogoCont = styled.div`
    width:90%;
    display:flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 0px auto;
    @media (max-width: 768px) {
        flex-wrap: wrap
    }
  
`