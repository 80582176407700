import React, { useContext } from 'react'
import styled from 'styled-components'
import { NavContext } from '../../context/NaviContext'

const NavItem =({sectionTxt, i, toogle, setToogle})=>{

    const [, setSection] = useContext(NavContext)

    return(
        <ItemWrap toogle={toogle} onClick={()=>[setSection(i), setToogle(!toogle)]}>{sectionTxt}</ItemWrap>
    )
}

export default NavItem

const ItemWrap = styled.div`
    font-family: "Barlow-Bold";
    font-size: calc(1.5rem + ((1vw - 19.2px) * 0.375));
    color: white;
    cursor:pointer;

    &:hover {
        color: var(--orange);
    }
`
