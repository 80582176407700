import React from 'react';
import { Column, Container } from '../../components/Containers/Containers';
import { H3, P} from '../../components/Text/Text';
import Card from '../../components/Card/Card';
import data from '../../data.json'
import styled from 'styled-components';
import Imagen from '../../components/Imagen';
import basic from '../../assets/simulator.png'
import plus from '../../assets/plus-simulator.png'
import useScreenSize from '../../hooks/useScreenSize';

const Compare = () => {

    const {width}=useScreenSize()

  return <Container style={{borderRadius: '16px', backgroundColor: '#474857'}} width={'90%'} justifyContent={'center'}  alignItems={'flex-end'}>
            <Column mt={'-48px'} style={{alignSelf: 'flex-end', order: width >= 768 ? 0 : 2}}  height={'100%'} width={width >= 768 ?'30%':'50%'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                {width >= 768?<H3 style={{ color: 'white', marginBottom: '12px', textAlign: 'left'}}>Comparación modelos DriVR</H3>:
                              <h4 style={{ width: '90%',  color: 'white', marginBottom: '4px', textAlign: 'center'}}>Comparación modelos DriVR</h4>}
                {
                    data.features.map((item, i)=>
                                <WrapperFeature alignItems={'center'} key={i}>
                                  <P textAlign={width<=768?'center':'left'} style={{ 
                                      marginLeft: '14px', 
                                      padding: width >= 768 ? '8px' : '4px', 
                                      lineHeight: 'calc(1rem + ((1.5vw - 19.2px) * 0.375))',
                                      fontSize: 'calc(1.2rem + ((1vw - 19.2px) * 0.375))'
                                    }}>{item.description}</P>  
                                </WrapperFeature>
                    )
                }
            </Column>
            <Column mt={'-48px'} style={{alignSelf: 'flex-end', order: width >= 768 ? 0 : 1}} width={width >= 768 ?'30%':'20%'} alignItems={'Center'} >
                <Card padding={width >= 768?"18%":"50%"} width={"40%"} radius={"10px"} height={"100%"}>
                    <Imagen src={basic} width={'100%'} height={'100%'} pos={'absolute'}/>
                    
                </Card>
                <LabelSim wd={width >= 768?'50%':'100%'} mt={'-12px'}>
                        <p style={{color:'#2F303A', fontWeight: 'Bold'}}>Basic</p>
                </LabelSim>
                {   
                    data.features.map((item, i)=>
                                <WrapperFeature key={i} justifyContent={'center'} alignItems={'center'}>
                                  <Bullet bg={item.include.basico}/> 
                                </WrapperFeature>
                    )
                }
            </Column>
            <Column mt={'-48px'} style={{alignSelf: 'flex-end', order: width >= 768 ? 0 : 3}}  width={width >= 768 ?'30%':'20%'} alignItems={'Center'}>
                <Card padding={width >= 768?"18%":"50%"} width={"40%"} radius={"10px"} height={"100%"}>
                    <Imagen src={plus} width={'100%'} height={'100%'} pos={'absolute'}/>
                </Card>
                <LabelSim wd={width >= 768?'50%':'100%'} mt={'-12px'}>
                        <p style={{color:'#2F303A', fontWeight: 'Bold'}}>Plus</p>
                </LabelSim>
                {   
                    data.features.map((item, i)=>
                                <WrapperFeature key={i} justifyContent={'center'} alignItems={'center'}>
                                  <Bullet bg={item.include.plus}/> 
                                </WrapperFeature>
                    )
                }
            </Column>
         </Container>;
};

export default Compare;

const WrapperFeature = styled.div`
    display: flex;
    width: 100%;
    background: linear-gradient(152.97deg, rgba(21, 21, 21, 0.2) 0%, rgba(21, 21, 21, 0) 100%);
    backdrop-filter: blur(42px);
    height: 60px;
    justify-content: ${props => props.justifyContent};
    align-items: ${props => props.alignItems}
`
const Bullet = styled.div`
    width: 12px;
    height: 12px;
    background-color: ${props => props.bg ? '#F6871F' : 'transparent'};
    border-radius: 16px;
`
const LabelSim = styled.div`
    position: absoute;
    bottom: 20;
    width: ${props => props.wd};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: ${props =>props.mt};
    z-index: 90;
    padding: 4px;
    background-color: #F6871F;
    border-radius: 8px;
    margin-bottom: 12px
`