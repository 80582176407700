import React, {useCallback} from 'react'
import styled from 'styled-components'
import Player from '../../components/Video/Player'
import Vidhero from '../../assets/Herov1.mp4'
import useScreenSize from '../../hooks/useScreenSize';


const Hero =(props)=>{

    const { forwardRef } = props;
    const {width}=useScreenSize()

    const setRefs = useCallback(
        (node)=>{
            forwardRef.current = node;
        }, [forwardRef])

    return(
        <HeroContainer ref={setRefs}>
            <HeroText><h1>Entornos virtuales <br/> para una <span>conducción segura</span></h1></HeroText>
            <PlayerCont width={width >=1025 ? '100vw':'300vw'}>
                <Player url={ Vidhero } 
                playing={true} 
                loop={true} 
                opacity={0.3} 
                width={"100%"} 
                height={"100%"} 
                muted={true}
                position={'absolute'}/>
            </PlayerCont>
        </HeroContainer>
    )
}

export default Hero

const HeroContainer = styled.div`
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
`

const HeroText = styled.div`
    position: absolute;
    bottom: 30%;
    left: 5%;
    z-index: 1;
`

const PlayerCont = styled.div`
    width: ${props=>props.width};
    aspect-ratio: 1;
    position: relative;
    overflow: hidden;
`

