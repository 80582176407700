import React from 'react'
import styled from 'styled-components'
import logoWhats from '../../assets/images/logo-whats.svg'

const StycktWhats = () => {
  return (
    <WrapperIcon>
      <a href='https://api.whatsapp.com/send?phone=523310183726'>
        <IconWhats src={logoWhats}/>
      </a>
    </WrapperIcon>
  )
}

export default StycktWhats

const WrapperIcon = styled.div`
    width: 60px;
    height: 60px;
    position: fixed;
    right: 2%;
    bottom: 4%;
    z-index: 90;
    cursor: pointer
`
const IconWhats = styled.img`
    width: 100%;
    height: 100%;
`